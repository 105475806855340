import { render, staticRenderFns } from "./PainelIndicadores.vue?vue&type=template&id=48e6fc18&scoped=true"
import script from "./PainelIndicadores.vue?vue&type=script&lang=js"
export * from "./PainelIndicadores.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e6fc18",
  null
  
)

export default component.exports