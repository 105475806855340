<template>
  <v-row justify="center">
    <v-row>
      <v-col>
        <div >
          <h1 class="h1">Painel de Indicadores</h1>
        </div>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>

import UtilsFunc from "@/service/utilsFunc";

const { withCRUDUtils } = UtilsFunc;

export default {
  name: 'Indicadores',
  components: { },
  data: () => withCRUDUtils({ }),
  computed: { },
  methods: { },
  mounted() { }
}
</script>

<style scoped>
 
</style>
